
import { BreadCrumb } from "@/models/utils/breadcrumb.interface";
import store from "@/store";
import PageTitleModule from "@/store/modules/pageTitle.module";
import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/modules/auth.module";
import { authenticate } from "@/services/api/authenticate.service";
import SearchBarModule from "@/store/modules/searchBar.module";
import ThemeModule from "@/store/modules/theme.module";
import { ProfileMenuFields } from "@/models/utils/profile-menu-fields.interface";
import { UserSignUp } from "@/models/entities/user-signup.interface";
import {
  VMenuDivider,
  VMenuItem,
} from "vuelando/dist/types/models/utils/v-menu-item.interface";

const pageTitleModule = getModule(PageTitleModule, store);
const authModule = getModule(AuthModule, store);
const searchBarModule = getModule(SearchBarModule, store);
const themeModule = getModule(ThemeModule, store);

@Component
export default class Layout extends Vue {
  private settingsDialog = false;
  private currentSearch = "";
  private componentKey = "";
  private timeout = null;
  path = require("@/assets/CBILogoRed.png");
  private beneficiaries = "";
  private leavePageDialog = false;
  private leavePageClick = "";
  private logoutDialog = false;
  private skip = true;
  private user: UserSignUp;

  private userMenuItems: ProfileMenuFields[] = [
    {
      fieldName: this.$tc("account.Overview"),
      icon: "chart",
      key: "overview",
    },
    {
      fieldName: this.$tc("account.PersonalData"),
      icon: "user",
      key: "personalData",
    },
    {
      fieldName: this.$tc("account.Password"),
      icon: "key",
      key: "modifyPassword",
    },
  ];

  private settingsMenuItems: (ProfileMenuFields | VMenuDivider)[] = [
    {
      fieldName: this.$tc("account.Payment"),
      icon: "box",
      key: "payments",
    },
    "divider",
    {
      fieldName: this.$tc("account.OrderingList"),
      icon: "clipboard",
      key: "orderingSubjectList",
    },
  ];

  private get roleDescription(): string {
    switch (authModule.profileRoleID) {
      case 1:
        return "Administrator";
      case 3:
        return this.$tc("general.Base");
      case 4:
        return this.$tc("general.Standard");
      case 5:
        return this.$tc("general.Pro");
      case 6:
        return this.$tc("general.Unlimited");
      default:
        return "";
    }
  }

  get breadcrumbs(): BreadCrumb[] {
    return pageTitleModule.breadcrumb;
  }

  get username(): string {
    return authModule.userName;
  }

  get email(): string {
    return authModule.userEmail;
  }

  get visibleSrcBar(): boolean {
    return searchBarModule.getVisible;
  }

  get currentPage(): string {
    return this.$route.params.context;
  }

  //   get darkMode() {
  //     return themeModule.dark;
  //   }

  @Watch("currentSearch")
  private currentSearchUpdated(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      searchBarModule.searchTable(
        this.currentSearch != null ? this.currentSearch : ""
      );
    }, 200);
  }

  private mounted() {
    this.componentKey = this.$route.params.context;
  }

  private changePage(): void {
    if (this.leavePageClick != this.currentPage) {
      this.leavePageDialog = false;
      this.$router.push({
        name: "account",
        params: {
          context: this.leavePageClick,
        },
      });
      searchBarModule.setVisibleAction(false);
    }
  }

  private logoutFunct(): void {
    this.leavePageDialog = false;
    this.$router.push({
      name: "Logout",
    });
  }

  private goToAccount(key: string): void {
    this.leavePageClick = key;
    if (searchBarModule.getVisible) {
      this.leavePageDialog = true;
    } else {
      this.changePage();
    }
  }

  private async logout(): Promise<void> {
    if (searchBarModule.getVisible && this.skip) {
      this.logoutDialog = true;
      this.skip = false;
    } else {
      this.skip = true;
      await authenticate.logout();
      this.$router.push({
        name: "Logout",
      });
    }
  }

  private goHome() {
    if (this.$route.path != "/home") this.$router.push({ name: "home" });
    else location.reload();
  }

  private onLinkClick(): void {
    window.open("https://www.zelando.it/", "_blank");
  }
}
